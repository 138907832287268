/*
 * This file is part of Banking App, licensed under GNU GPLv3 license.
 * Copyright (C) 2022 yawek9
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

class TokenService {
  getAccessToken() {
    return JSON.parse(localStorage.getItem("user"))?.accessToken;
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem("user"))?.refreshToken;
  }

  updateTokens(accessToken, refreshToken) {
    let userData = JSON.parse(localStorage.getItem("user"));
    userData.accessToken = accessToken;
    userData.refreshToken = refreshToken;
    localStorage.setItem("user", JSON.stringify(userData));
  }
}

export default new TokenService();
